<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "BusinessRuleActions.NewBusinessRuleAction",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        @methodSave="onSubmit('0', 'btn-save')"
        @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
        @clear="onClear()"
        :isNewRecord="true"
      />
    </div>
  </div>
  <form class="form-businessruleactionsendxtrareport-new pe-2 ps-2" novalidate>
    <div v-if="errors.length > 0" class="alert alert-warning">
      <ul class="mb-0">
        <li v-for="error in errors" v-bind:key="error">
          {{ error }}
        </li>
      </ul>
    </div>
    <div v-if="isSuccess" class="alert alert-success">
      {{
        $t(
          "Messages.SuccessMessage",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
    <div class="row row-cols-1">
      <div class="col col-md-6">
        <label for="Name" class="form-label required"
          >{{
            $t(
              "BusinessRuleActions.Name",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="businessRuleActionData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="BusinessActionType" class="form-label"
          >{{
            $t(
              "BusinessRuleActions.BusinessActionType",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          disabled
          id="BusinessActionType"
          v-model="businessRuleActionType"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
    </div>
    <div class="row row-cols-1">
      <div class="col col-md-12 mt-3">
        <label for="Description" class="form-label">{{
          $t(
            "BusinessRuleActions.Description",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <textarea
          name="Description"
          class="form-control"
          id="Description"
          v-model="businessRuleActionData.description"
          :spellcheck="this.$isTextSpellCheck"
          rows="2"
        ></textarea>
      </div>
      <div class="col col-md-12 mt-3">
        <label for="ResponseText" class="form-label">{{
          $t(
            "BusinessRuleActions.ResponseText",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <textarea
          name="ResponseText"
          class="form-control"
          id="ResponseText"
          v-model="businessRuleActionData.responseText"
          :spellcheck="this.$isTextSpellCheck"
          rows="1"
        ></textarea>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div style="display: none" class="form-check isForcingSave">
        <label for="isForcingSave" class="form-label">{{
          $t(
            "BusinessRuleActions.IsForcingSave",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="isForcingSave"
          v-model="businessRuleActionData.isForcingSave"
        />
      </div>
      <div class="form-check">
        <label for="IsActive" class="form-label">{{
          $t(
            "BusinessRuleActions.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsActive"
          v-model="businessRuleActionData.isActive"
        />
      </div>
    </div>
    <div class="row row-cols-12 mt-3 sendXtraReport">
      <div class="col col-md-6">
        <label for="NotificationType" class="form-label required">{{
          $t(
            "BusinessRuleActions.MessageTemplateType",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :allowEmpty="false"
          :selectedData="selectedNotificationType"
          :isDisabled="true"
          :data="notificationTypeData"
        />
      </div>
      <div class="col col-md-6">
        <label for="NotificationSettings" class="form-label required">{{
          $t(
            "BusinessRuleActions.NotificationSettings",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryNotifierSettings?type={0}',
              this.notificationType
            )
          "
          @onChange="onChangeForNotifierSettings"
        />
      </div>
    </div>
    <div class="card mt-2">
      <div class="card-body">
        <h3 class="card-title">
          <i class="bi bi-sliders"></i>
          {{
            $t(
              "BaseModelFields.CustomSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </h3>
        <div class="row row-cols-1">
          <div class="col col-md-6 mt-3">
            <label for="SelectMessageTemplate" class="form-label required">{{
              $t(
                "BusinessRuleActions.SelectMessageTemplate",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <FormSelect
              type="SelectWithRemoteDataAndInternalSearch"
              :allowEmpty="false"
              :isParameters="true"
              :isGatewayRequest="true"
              :requestUrl="
                String.format(
                  '/Lcdp-SummaryMessageTemplates?type={0}',
                  this.notificationType
                )
              "
              @onChange="onChangeForMessageTemplate"
            />
          </div>
          <div class="col col-md-6 mt-3">
            <label for="ReportExportType" class="form-label required">{{
              $t(
                "BusinessRuleActions.ReportExportType",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <FormSelect
              type="SelectWithLocalData"
              :allowEmpty="false"
              :selectedData="selectedReportExportType"
              :data="reportExportTypeData"
              @onChange="onChangeForReportExportTypeId"
            />
          </div>
          <div class="col col-md-6 mt-3">
            <label for="Subject" class="form-label required"
              >{{
                $t(
                  "BusinessRuleActions.Subject",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <input
              type="text"
              class="form-control"
              id="Subject"
              v-model="businessRuleActionData.subject"
              :autocomplete="this.$isAutoComplete"
              :spellcheck="this.$isTextSpellCheck"
              required
            />
          </div>
          <div class="col col-md-6 mt-3">
            <label for="XtraReportId" class="form-label required"
              >{{
                $t(
                  "BusinessRuleActions.XtraReportId",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <input
              type="number"
              class="form-control"
              id="XtraReportId"
              v-model="businessRuleActionData.xtraReportId"
              :autocomplete="this.$isAutoComplete"
              required
            />
          </div>
        </div>
        <ul class="nav nav-tabs mb-2 mt-0 tabs sendNotification" role="tablist">
          <li class="nav-item toWhoTab" role="presentation">
            <button
              class="nav-link active"
              id="pills-to-who-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-to-who"
              type="button"
              role="tab"
              aria-selected="true"
            >
              {{
                $t(
                  "BusinessRuleActions.ToWho",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </li>
          <li class="nav-item ccTab" role="presentation">
            <button
              class="nav-link"
              id="pills-cc-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-cc"
              type="button"
              role="tab"
              aria-selected="false"
            >
              {{
                $t(
                  "BusinessRuleActions.CC",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </li>
          <li class="nav-item bccTab" role="presentation">
            <button
              class="nav-link"
              id="pills-cc-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-bcc"
              type="button"
              role="tab"
              aria-selected="false"
            >
              {{
                $t(
                  "BusinessRuleActions.BCC",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </li>
        </ul>
        <div class="tab-content tabs sendNotification">
          <div
            class="tab-pane fade active show"
            id="pills-to-who"
            role="tabpanel"
          >
            <div class="row row-cols-12 mt-3 timeTriggeredTab">
              <div class="col col-md-12">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceType"
                  :data="sourceTypeDataForTo"
                  @onChange="onChangeForSourceTypeToWhoTimeTriggered"
                  openDirection="top"
                />
              </div>
              <div v-if="this.hideSourceTo" class="col col-md-12 mt-3 sourceTo">
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedUser"
                  :data="userData"
                  @onChange="onChangeForSourceSelectTo"
                  openDirection="top"
                />
              </div>
              <div v-if="this.hideUserTo" class="col col-md-12 mt-3 userTo">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForUserTo"
                  :isParameters="true"
                  :allowEmpty="false"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=User&filterType=User&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                v-if="this.hideUserGroupTo"
                class="col col-md-12 mt-3 userGroupTo"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForUserGroupTo"
                  :isParameters="true"
                  :allowEmpty="false"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup&filterType=UserGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                v-if="this.hidePermissionGroupTo"
                class="col col-md-12 mt-3 permissionGroupTo"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForPermissionGroupTo"
                  :isParameters="true"
                  :allowEmpty="false"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup&filterType=PermissionGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                v-if="this.hideSourceTextTo"
                class="col col-md-12 mt-3 sourceTo"
              >
                <label for="Source" class="form-label required"
                  >{{
                    $t(
                      "BusinessRuleActions.EmailAdressInformation",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="Source"
                  v-model="businessRuleActionData.recipientsToText"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-cc" role="tabpanel">
            <div class="row row-cols-12 mt-3">
              <div class="col col-md-12 timeTriggeredCc">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceType"
                  :data="sourceTypeDataForCcBcc"
                  @onChange="onChangeForSourceTypeTimeTriggeredCc"
                  openDirection="top"
                />
              </div>
              <div
                v-if="this.hideSourceTextCc"
                class="col col-md-12 mt-3 sourceTextCc"
              >
                <label for="source" class="form-label"
                  >{{
                    $t(
                      "BusinessRuleActions.Source",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="sourceTextCc"
                  v-model="businessRuleActionData.recipientsCCText"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div v-if="this.hideSourceCc" class="col col-md-12 mt-3 sourceCc">
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedUser"
                  :data="userData"
                  @onChange="onChangeForSourceSelectCc"
                  openDirection="top"
                />
              </div>
              <div v-if="this.hideUserCc" class="col col-md-12 mt-3 userCc">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForUserCc"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=User&filterType=User&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                v-if="this.hideUserGroupCc"
                class="col col-md-12 mt-3 userGroupCc"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForUserGroupCc"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup&filterType=UserGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                v-if="this.hidePermissionGroupCc"
                class="col col-md-12 mt-3 permissionGroupCc"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForPermissionGroupCc"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup&filterType=PermissionGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div v-if="this.hideRelatedCc" class="col col-md-12 mt-3">
                <label for="source" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :data="relatedSource"
                  @onChange="onChangeForRelatedCc"
                  openDirection="top"
                />
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-bcc" role="tabpanel">
            <div class="row row-cols-12 mt-3">
              <div class="col col-md-12 timeTriggeredBcc">
                <label for="sourceType" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.SourceType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedSourceType"
                  :data="sourceTypeDataForCcBcc"
                  @onChange="onChangeForSourceTypeTimeTriggeredBcc"
                  openDirection="top"
                />
              </div>
              <div
                v-if="this.hideSourceTextBcc"
                class="col col-md-12 mt-3 sourceTextBcc"
              >
                <label for="source" class="form-label"
                  >{{
                    $t(
                      "BusinessRuleActions.Source",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="sourceTextBcc"
                  v-model="businessRuleActionData.recipientsBCCText"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div
                v-if="this.hideSourceBcc"
                class="col col-md-12 mt-3 sourceBcc"
              >
                <label for="sourceSelect" class="form-label required">{{
                  $t(
                    "BusinessRuleActions.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :allowEmpty="false"
                  :selectedData="selectedUser"
                  :data="userData"
                  @onChange="onChangeForSourceSelectBcc"
                  openDirection="top"
                />
              </div>
              <div v-if="this.hideUserBcc" class="col col-md-12 mt-3 userBcc">
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForUserBcc"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=User&filterType=User&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                v-if="this.hideUserGroupBcc"
                class="col col-md-12 mt-3 userGroupBcc"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForUserGroupBcc"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup&filterType=UserGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
              <div
                v-if="this.hidePermissionGroupBcc"
                class="col col-md-12 mt-3 permissionGroupBcc"
              >
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isMultiple="true"
                  @onMultipleSelectChange="onChangeForPermissionGroupBcc"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup&filterType=PermissionGroup&isAddCurrentKeys=false'
                    )
                  "
                  openDirection="top"
                />
              </div>
            </div>
            <div v-if="this.hideRelatedBcc" class="col col-md-12 mt-3">
              <label for="source" class="form-label required">{{
                $t(
                  "BusinessRuleActions.Source",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <FormSelect
                type="SelectWithLocalData"
                :allowEmpty="false"
                :data="relatedSource"
                @onChange="onChangeForRelatedBcc"
                openDirection="top"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from "jquery";
export default {
  name: "CustomObjectNewBusinessRuleActionSendXtraReport",
  data() {
    return {
      notificationTypeData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.Email",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.SMS",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.SystemNotification",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedNotificationType: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.Email",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedNotifierSetting: [],
      selectedMessageTemplate: [],
      reportExportTypeData: [
        {
          key: 1,
          value: this.$t(
            "ReportExportTypes.Pdf",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "ReportExportTypes.Excel",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "ReportExportTypes.Word",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 4,
          value: this.$t(
            "ReportExportTypes.Csv",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 5,
          value: this.$t(
            "ReportExportTypes.ExcelXLS",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedReportExportType: [
        {
          key: 1,
          value: this.$t(
            "ReportExportTypes.Pdf",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      sourceTypeData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FromTheText",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.FromTheFields",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      sourceTypeDataForTo: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FromTheText",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      sourceTypeDataForCcBcc: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FromTheText",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.FromTheOrganizationUnitsOfTheCompany",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 4,
          value: this.$t(
            "BusinessRuleActions.Related",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedSourceType: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FromTheText",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      relatedSource: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.FirstManager",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.SecondManager",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.ThirdManager",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 4,
          value: this.$t(
            "BusinessRuleActions.Teammate",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      userData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.User",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.UserGroup",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRuleActions.PermissionGroup",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedUser: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.User",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedOrganizationalUnitItems: [],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      hideRelatedBcc: false,
      hideRelatedCc: false,
      hideSourceTextCc: true,
      hideSourceCc: false,
      hideUserCc: false,
      hideUserGroupCc: false,
      hidePermissionGroupCc: false,
      hideSourceTextBcc: true,
      hideSourceBcc: false,
      hideUserBcc: false,
      hideUserGroupBcc: false,
      hidePermissionGroupBcc: false,
      hideSourceTextTo: true,
      hideSourceTo: false,
      hideUserTo: false,
      hideUserGroupTo: false,
      hidePermissionGroupTo: false,
      errors: [],
      createRecordFieldFormulasForEdit: [],
      businessRuleActionData: {
        businessRulePublicId: this.$route.params.businessRuleId,
        customObjectPublicId: this.$route.params.customObjectId,
        name: "",
        description: "",
        responseText: "",
        isActive: true,
        MessageTemplateTypeId: 1,
        MessageTemplateTypeName: "Email",
        IsRunSeparatelyForEachUser: false,
        IsNotSendEmailIfThereIsNoRecord: false,
        subject: "",
        RecipientsToInfoType: 1,
        recipientsCCInfoType: 1,
        RecipientsBCCInfoType: 1,
      },
      businessRuleActionType: "",
      businessRuleTriggerTypeId: null,
      notificationType: 1,
      AttachementId: null,
      sourceSelect: "User",
      fieldsType: "Email|FormulaOrganizationalUnit",
      businessActionObject: [],
      detailForwardOrEditForwardType: false,
      isSuccess: false,
      multipleSelect: this.$t(
        "Fields.MultipleSelect",
        {},
        { locale: this.$store.state.activeLang }
      ),
      select: this.$t(
        "Fields.Select",
        {},
        { locale: this.$store.state.activeLang }
      ),
      table: this.$t(
        "Fields.Table",
        {},
        { locale: this.$store.state.activeLang }
      ),
    };
  },
  methods: {
    onChangeForSourceTypeToWhoTimeTriggered: function (selected) {
      this.businessRuleActionData.recipientsToInfoType = selected.key;
      this.businessRuleActionData.recipientsTo = "";
      this.businessRuleActionData.recipientsToText = "";
      this.businessRuleActionData.recipientsToOrgUnitUser = "";
      this.businessRuleActionData.recipientsToOrgUnitUserGroup = "";
      this.businessRuleActionData.recipientsToOrgUnitPermissionGroup = "";
      if (selected.key == "1") {
        this.hideSourceTo = false;
        this.hideUserTo = false;
        this.hideSourceTextTo = true;
        this.hideUserGroupTo = false;
        this.hidePermissionGroupTo = false;
      } else if (selected.key == "2") {
        this.hideSourceTo = true;
        this.hideUserTo = true;
        this.hideSourceTextTo = false;
      }
    },
    onChangeForSourceTypeTimeTriggeredCc: function (selected) {
      this.businessRuleActionData.recipientsCCInfoType = selected.key;
      this.businessRuleActionData.recipientsCC = "";
      this.businessRuleActionData.recipientsCCText = "";
      this.businessRuleActionData.recipientsCCOrgUnitUser = "";
      this.businessRuleActionData.recipientsCCOrgUnitUserGroup = "";
      this.businessRuleActionData.recipientsCCOrgUnitPermissionGroup = "";
      this.businessRuleActionData.recipientsCCFromRelationTo = "";
      if (selected.key == "1") {
        this.hideSourceTextCc = true;
        this.hideSourceCc = false;
        this.hideUserCc = false;
        this.hideUserGroupCc = false;
        this.hidePermissionGroupTo = false;
        this.hideRelatedCc = false;
      } else if (selected.key == "2") {
        this.hideSourceCc = true;
        this.hideUserCc = true;
        this.hideSourceTextCc = false;
        this.hideRelatedCc = false;
      } else if (selected.key == "4") {
        this.hideSourceCc = false;
        this.hideUserCc = false;
        this.hideUserGroupCc = false;
        this.hidePermissionGroupTo = false;
        this.hideSourceTextCc = false;
        this.hideRelatedCc = true;
      }
    },
    onChangeForSourceTypeTimeTriggeredBcc: function (selected) {
      this.businessRuleActionData.RecipientsBCCInfoType = selected.key;
      this.businessRuleActionData.recipientsBCC = "";
      this.businessRuleActionData.recipientsBCCText = "";
      this.businessRuleActionData.recipientsBCCOrgUnitUser = "";
      this.businessRuleActionData.recipientsBCCOrgUnitUserGroup = "";
      this.businessRuleActionData.recipientsBCCOrgUnitPermissionGroup = "";
      this.businessRuleActionData.recipientsBCCFromRelationTo = "";
      if (selected.key == "1") {
        this.hideSourceTextBcc = true;
        this.hideSourceBcc = false;
        this.hideUserBcc = false;
        this.hideUserGroupBcc = false;
        this.hidePermissionGroupBcc = false;
        this.hideRelatedBcc = false;
      } else if (selected.key == "2") {
        this.hideSourceTextBcc = false;
        this.hideSourceBcc = true;
        this.hideUserBcc = true;
        this.hideRelatedBcc = false;
      } else if (selected.key == "4") {
        this.hideSourceTextBcc = false;
        this.hideSourceBcc = false;
        this.hideUserBcc = false;
        this.hideUserGroupBcc = false;
        this.hidePermissionGroupBcc = false;
        this.hideRelatedBcc = true;
      }
    },
    onChangeForSourceSelectCc: function (selected) {
      this.businessRuleActionData.recipientsCCOrganizationalUnit = selected.key;
      if (selected.key == "1") {
        this.sourceSelect = "User";
        this.hideUserCc = true;
        this.hidePermissionGroupCc = false;
        this.hideUserGroupCc = false;
      } else if (selected.key == "2") {
        this.sourceSelect = "UserGorup";
        this.hideUserCc = false;
        this.hideUserGroupCc = true;
        this.hidePermissionGroupCc = false;
      } else if (selected.key == "3") {
        this.sourceSelect = "PermissionGroup";
        this.hideUserCc = false;
        this.hideUserGroupCc = false;
        this.hidePermissionGroupCc = true;
      }
    },
    onChangeForSourceSelectBcc: function (selected) {
      this.businessRuleActionData.recipientsBCCOrganizationalUnit =
        selected.key;
      if (selected.key == "1") {
        this.sourceSelect = "User";
        this.hideUserBcc = true;
        this.hideUserGroupBcc = false;
        this.hidePermissionGroupBcc = false;
      } else if (selected.key == "2") {
        this.sourceSelect = "UserGorup";
        this.hideUserBcc = false;
        this.hideUserGroupBcc = true;
        this.hidePermissionGroupBcc = false;
      } else if (selected.key == "3") {
        this.sourceSelect = "PermissionGroup";
        this.hideUserBcc = false;
        this.hideUserGroupBcc = false;
        this.hidePermissionGroupBcc = true;
      }
    },
    onChangeForSourceSelectTo: function (selected) {
      this.businessRuleActionData.recipientsToOrganizationalUnit = selected.key;
      if (selected.key == "1") {
        this.sourceSelect = "User";
        this.hideUserTo = true;
        this.hideUserGroupTo = false;
        this.hidePermissionGroupTo = false;
      } else if (selected.key == "2") {
        this.sourceSelect = "UserGorup";
        this.hideUserTo = false;
        this.hideUserGroupTo = true;
        this.hidePermissionGroupTo = false;
      } else if (selected.key == "3") {
        this.sourceSelect = "PermissionGroup";
        this.hideUserTo = false;
        this.hideUserGroupTo = false;
        this.hidePermissionGroupTo = true;
      }
    },
    onChangeForUserTo: function (ids) {
      this.businessRuleActionData.recipientsToOrgUnitUser = ids;
      this.businessRuleActionData.recipientsToOrgUnitUserGroup = "";
      this.businessRuleActionData.recipientsToOrgUnitPermissionGroup = "";
    },
    onChangeForUserGroupTo: function (ids) {
      this.businessRuleActionData.recipientsToOrgUnitUser = "";
      this.businessRuleActionData.recipientsToOrgUnitUserGroup = ids;
      this.businessRuleActionData.recipientsToOrgUnitPermissionGroup = "";
    },
    onChangeForPermissionGroupTo: function (ids) {
      this.businessRuleActionData.recipientsToOrgUnitUser = "";
      this.businessRuleActionData.recipientsToOrgUnitUserGroup = "";
      this.businessRuleActionData.recipientsToOrgUnitPermissionGroup = ids;
    },
    onChangeForUserCc: function (ids) {
      this.businessRuleActionData.recipientsCCOrgUnitUser = ids;
      this.businessRuleActionData.recipientsCCOrgUnitUserGroup = "";
      this.businessRuleActionData.recipientsCCOrgUnitPermissionGroup = "";
    },
    onChangeForUserGroupCc: function (ids) {
      this.businessRuleActionData.recipientsCCOrgUnitUser = "";
      this.businessRuleActionData.recipientsCCOrgUnitUserGroup = ids;
      this.businessRuleActionData.recipientsCCOrgUnitPermissionGroup = "";
    },
    onChangeForPermissionGroupCc: function (ids) {
      this.businessRuleActionData.recipientsCCOrgUnitUser = "";
      this.businessRuleActionData.recipientsCCOrgUnitUserGroup = "";
      this.businessRuleActionData.recipientsCCOrgUnitPermissionGroup = ids;
    },
    onChangeForUserBcc: function (ids) {
      this.businessRuleActionData.recipientsBCCOrgUnitUser = ids;
      this.businessRuleActionData.recipientsBCCOrgUnitUserGroup = "";
      this.businessRuleActionData.recipientsBCCOrgUnitPermissionGroup = "";
    },
    onChangeForUserGroupBcc: function (ids) {
      this.businessRuleActionData.recipientsBCCOrgUnitUser = "";
      this.businessRuleActionData.recipientsBCCOrgUnitUserGroup = ids;
      this.businessRuleActionData.recipientsBCCOrgUnitPermissionGroup = "";
    },
    onChangeForPermissionGroupBcc: function (ids) {
      this.businessRuleActionData.recipientsBCCOrgUnitUser = "";
      this.businessRuleActionData.recipientsBCCOrgUnitUserGroup = "";
      this.businessRuleActionData.recipientsBCCOrgUnitPermissionGroup = ids;
    },
    onChangeForRelatedCc: function (selected) {
      this.businessRuleActionData.recipientsCCFromRelationTo =
        selected.key.toString();
    },
    onChangeForRelatedBcc: function (selected) {
      this.businessRuleActionData.recipientsBCCFromRelationTo =
        selected.key.toString();
    },
    getCustomObjectDetail() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-CustomObjectGet?id={0}",
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.businessActionObject = response.data;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onChangeForNotifierSettings: function (selected) {
      this.businessRuleActionData.NotifierSettingPublicId = selected.key;
      this.businessRuleActionData.NotifierSettingName = selected.value;
    },
    onChangeForMessageTemplate: function (selected) {
      this.businessRuleActionData.MessageTemplatePublicId = selected.key;
    },
    onChangeForReportExportTypeId: function (selected) {
      this.businessRuleActionData.ReportExportTypeId = selected.key;
    },
    onClear() {
      this.businessRuleActionData = {
        customObjectPublicId: this.$route.params.customObjectId,
        businessRulePublicId: this.$route.params.businessRuleId,
        name: "",
        formula: "",
        errorText: "",
        isShowMessageInModal: false,
        isActive: true,
      };

      $(".form-businessruleactionsendxtrareport-new").removeClass(
        "was-validated"
      );
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-businessruleactionsendxtrareport-new");
      form.addClass("was-validated");
      if (
        !this.$root.brActionValidity(form[0]) ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }
      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);
      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Brs-BusinessRuleActionNewActionSendXtraReport", {
          ...this.businessRuleActionData,
        })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push(
                "/CustomObject/BusinessRuleActions/" +
                  this.$route.params.customObjectId +
                  "&businessRuleId=" +
                  this.$route.params.businessRuleId
              );
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            this.errors.push(response.msg);
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  mounted() {
    this.getCustomObjectDetail();
    this.businessRuleTriggerTypeId = localStorage.getItem(
      "businessRuleTriggerTypeId"
    );
    this.businessRuleActionType = localStorage.getItem(
      "businessRuleActionType"
    );
  },
  watch: {
    "$route.params.customObjectId"() {
      this.businessRuleActionData.customObjectPublicId =
        this.$route.params.customObjectId;
    },
  },
};
</script>
